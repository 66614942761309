import {extend} from 'flarum/extend';
import avatar from 'flarum/helpers/avatar';
import Page from 'flarum/components/Page';
import IndexPage from 'flarum/components/IndexPage';
import Button from 'flarum/components/Button';
import ItemList from 'flarum/utils/ItemList';
import LogInModal from 'flarum/components/LogInModal';
import LoadingIndicator from 'flarum/components/LoadingIndicator';
import listItems from 'flarum/helpers/listItems';
import username from 'flarum/helpers/username';

export default class RankingsPage extends Page {
    init() {
        super.init();

        if (!app.session.user || app.session.user.data.attributes.canViewRankingPage !== true) {
            m.route('/');
        }

        this.monthOptions = [
            {name: app.translator.trans('tomaszrup.forum.months.jan'), value: 1},
            {name: app.translator.trans('tomaszrup.forum.months.feb'), value: 2},
            {name: app.translator.trans('tomaszrup.forum.months.mar'), value: 3},
            {name: app.translator.trans('tomaszrup.forum.months.apr'), value: 4},
            {name: app.translator.trans('tomaszrup.forum.months.may'), value: 5},
            {name: app.translator.trans('tomaszrup.forum.months.jun'), value: 6},
            {name: app.translator.trans('tomaszrup.forum.months.jul'), value: 7},
            {name: app.translator.trans('tomaszrup.forum.months.aug'), value: 8},
            {name: app.translator.trans('tomaszrup.forum.months.sep'), value: 9},
            {name: app.translator.trans('tomaszrup.forum.months.oct'), value: 10},
            {name: app.translator.trans('tomaszrup.forum.months.nov'), value: 11},
            {name: app.translator.trans('tomaszrup.forum.months.dec'), value: 12}
        ];

        let now = new Date;

        this.monthly = {
            year: now.getFullYear(),
            month: now.getMonth() + 1,
            active: {
                queried: false,
                year: null,
                month: null
            }
        };

        this.changeYear = this.changeYear.bind(this);
        this.changeMonth = this.changeMonth.bind(this);
        this.queryMonthly = this.queryMonthly.bind(this);
        this.getQueriedMonthString = this.getQueriedMonthString.bind(this);

        this.loading = true;
        this.users = [];
        this.queryMonthly();
        //this.refresh();
    }

    view() {
        let loading;

        if (this.loading) {
            loading = LoadingIndicator.component();
        } else if(!this.monthly.active.queried) {
            loading = '';
        }
        return (
            <div className="TagsPage">
            {IndexPage.prototype.hero()}
            <div className="container">
            <nav className="RankingPage-nav IndexPage-nav sideNav" config={IndexPage.prototype.affixSidebar}>
            <ul>{listItems(IndexPage.prototype.sidebarItems().toArray())}</ul>
         </nav>
        <div className="RankingPage sideNavOffset">
        <div className="datePicker">
            <div>
            <input className="FormControl" type="number" min="2020" value={this.monthly.year}
        onchange={this.changeYear}/>
        </div>
        <div>
        <select className="FormControl" value={this.monthly.month} onchange={this.changeMonth}>
            {this.monthOptions.map((option) => [
                    <option value={option.value}> {option.name} </option>
    ])}
    </select>
        </div>
        <div>
        <button className="Button" onclick={this.queryMonthly}>{ app.translator.trans('tomaszrup.forum.show_button') }</button>
        </div>
        </div>
        <p className="rankingMonthIndicator">
        { this.monthly.active.queried ? app.translator.trans('tomaszrup.forum.current_ranking') + ':' : ''} {this.monthly.active.queried ? this.getQueriedMonthString() : ''}
    </p>
            <table class="rankings">
            <tr>
            <th className="rankings-mobile">{app.translator.trans('fof-gamification.forum.ranking.rank')}</th>
            <th>{app.translator.trans('fof-gamification.forum.ranking.name')}</th>
            <th>{app.translator.trans('fof-gamification.forum.ranking.amount')}</th>
            </tr>
        {this.users.map((user, i) => {
            ++i;
            return [
                <tr className={'ranking-' + i}>
            {i < 4 ? (
                    app.forum.attribute('CustomRankingImages') == '1' ? (
                        <img
                        className="rankings-mobile rankings-image"
                src={app.forum.attribute('baseUrl') + app.forum.attribute('topimage' + i + 'Url')}
            />
        ) : (
            <td className={'rankings-mobile rankings-' + i}>
                <i className="icon fas fa-trophy"></i>
                </td>
        )
        ) : (
            <td className="rankings-4 rankings-mobile">{this.addOrdinalSuffix(i)}</td>
        )}
        <td>
            <div className="PostUser">
                <h3 className="rankings-info">
                <a href={app.route.user(user)} config={m.route}>
            {i < 4 ? avatar(user, {className: 'info-avatar rankings-' + i + '-avatar'}) : ''}{' '}
            {username(user)}
        </a>
            </h3>
            </div>
            </td>
            {i < 4 ? (
                <td className={'rankings-' + i}>
                {!this.monthly.active.queried ? user.data.attributes.Points : user.data.attributes.MonthlyPoints}
            </td>
            ) : (
            <td className="rankings-4">
                {!this.monthly.active.queried ? user.data.attributes.Points : user.data.attributes.MonthlyPoints}
            </td>
            )}
        </tr>,
        ];
        })}
    </table>
        <div className="rankings-loadmore"> {loading}</div>
            </div>
            </div>
            </div>
    );
    }

    refresh(clear = true) {
        if (clear) {
            this.loading = true;
            this.users = [];
        }

        return this.loadResults().then(
            results => {
                this.users = [];
                this.parseResults(results);
            },
            () => {
                this.loading = false;
                m.redraw();
            }
        );
    }

    addOrdinalSuffix(i) {
        if (app.forum.attribute('DefaultLocale') == 'en') {
            var j = i % 10,
                k = i % 100;
            if (j == 1 && k != 11) {
                return i + 'st';
            }
            if (j == 2 && k != 12) {
                return i + 'nd';
            }
            if (j == 3 && k != 13) {
                return i + 'rd';
            }
            return i + 'th';
        } else {
            return i;
        }
    }

    getQueriedMonthString() {
        let month = this.monthOptions[this.monthly.active.month - 1].name;

        return month + " " + this.monthly.active.year;
    }

    changeYear(event) {
        this.monthly.year = event.target.value;
    }

    changeMonth(event) {
        this.monthly.month = event.target.value;
    }

    queryMonthly() {
        this.loading = true;
        this.users = [];
        this.monthly.active = {
            queried: true,
            month: this.monthly.month,
            year: this.monthly.year
        };

        return this.loadResults(0, true).then(
            results => {
                this.users = [];
                this.parseResults(results);
            },
            () => {
                this.loading = false;
                m.redraw();
            }
        );
    }

    stickyParams() {
        return {
            sort: m.route.param('sort'),
            q: m.route.param('q'),
        };
    }

    actionItems() {
        const items = new ItemList();

        items.add(
            'refresh',
            Button.component({
                title: app.translator.trans('core.forum.index.refresh_tooltip'),
                icon: 'fas fa-refresh',
                className: 'Button Button--icon',
                onclick: () => {
                    app.cache.discussionList.refresh();
                    if (app.session.user) {
                        app.store.find('users', app.session.user.id());
                        m.redraw();
                    }
                },
            })
        );

        return items;
    }

    newDiscussion() {
        const deferred = m.deferred();

        if (app.session.user) {
            this.composeNewDiscussion(deferred);
        } else {
            app.modal.show(
                new LogInModal({
                    onlogin: this.composeNewDiscussion.bind(this, deferred),
                })
            );
        }

        return deferred.promise;
    }

    composeNewDiscussion(deferred) {
        const component = new DiscussionComposer({user: app.session.user});

        app.composer.load(component);
        app.composer.show();

        deferred.resolve(component);

        return deferred.promise;
    }

    loadResults(offset, monthly = false) {
        const params = {};

        params.page = {
            offset: offset,
            limit: '10',
        };

        if(!monthly) {
            return app.store.find("rankings");
        } else {
            return app.store.find(`rankings/${this.monthly.year}-${this.monthly.month}`);
        }

    }

    loadMore() {
        if(this.monthly.active.queried) {
            return false;
        }
        this.loading = true;

        this.loadResults(this.users.length).then(this.parseResults.bind(this));
    }

    parseResults(results) {
        [].push.apply(this.users, results);

        this.loading = false;

        this.users.sort(function (a, b) {
            return parseFloat(b.data.attributes.MonthlyPoints) - parseFloat(a.data.attributes.MonthlyPoints);
        });

        m.lazyRedraw();

        return results;
    }
}
