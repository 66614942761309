import { extend } from 'flarum/extend';
import IndexPage from 'flarum/components/IndexPage';
import LinkButton from 'flarum/components/LinkButton';

export default function () {    
    extend(IndexPage.prototype, 'navItems', function (items) {
        items.add(
            'monthly',
            LinkButton.component({
                href: app.route('monthly'),
                children: app.translator.trans('tomaszrup.forum.link'),
                icon: 'fas fa-award',
            }),
            80
        );
    });
}