import GetAppModal from './GetAppModal';
import getCookie from './getCookie';

function showModal() {
    app.modal.show(
        new GetAppModal()
    );
}

export default function() {
    if( /Android|IEMobile|Opera Mini/i.test(navigator.userAgent) && !localStorage.seenAppModal && !getCookie('fcm_token')) {
        setTimeout(showModal, 1000);
        localStorage.seenAppModal = true;
    }
}
