function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
}

export default function () {
    var cookie = getCookie("fcm_token");
    
    if (typeof cookie == 'undefined' || !cookie || cookie == '') {
        return;
    }
    
    if(typeof app.session.user == 'undefined') {
        return;
    }

    app.request({
        method: 'POST',
        url: app.forum.attribute('apiUrl') + '/fcm_token',
        data: { "fcm_token": cookie }
    });
}