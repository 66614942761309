import Component from 'flarum/Component';
import LinkButton from 'flarum/components/LinkButton';
import NotificationsDropdown from 'flarum/components/NotificationsDropdown';

export default class Footer extends Component {
    init() {
        super.init();
        
        this.data = [
            { icon: "fas fa-home", name: "Strona główna", url: "/" },
            { icon: "fas fa-star", name: "Obserwowane", url: "/following" },
            { icon: "fas fa-trophy", name: "Konkursy", url: "/t/konkursy" },
            { icon: "fas fa-handshake", name: "Kooperacja", url: "/t/kooperacja" },
            { icon: "fas fa-award ", name: "Ranking miesięczny", url: "/monthly" }
        ]
    }
    getUnreadCount() {
        if(typeof app.session.user === 'undefined') return false;
        return app.session.user.unreadNotificationCount();
    }
    share() {
        const shareData = {
            title: 'MDN',
            text: 'Sprawdź forum GPPH!',
            url: 'https://forum.gpph.pl/',
        }

        if (typeof Android !== 'undefined') {
            Android.share(JSON.stringify(shareData));
        } else if (navigator) {
            navigator.share(shareData);
        }
    }
    notifications() {
        const unread = this.getUnreadCount();

        return (
            unread ? <span class="notifs-tag"> {unread} </span> : ""
        )
    }
    view() {
        return (
            <div className="rwd-footer">
                <div className="container">
                    <div className="footer-nav">
                        {
                            this.data.map(item => (
                                LinkButton.component({
                                    href: item.url,
                                    icon: item.icon,
                                    className: "footer-nav-link"
                                })
                            ))
                        }
                        {
                            LinkButton.component({
                                href: '/notifications',
                                icon: "fas fa-bell",
                                className: "footer-nav-link",
                                children: this.notifications()
                            })
                        }
                        <a className="footer-nav-link hasIcon" onclick={this.share}>
                            <i class="icon fas fa-share-alt"></i>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
