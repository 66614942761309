import Modal from 'flarum/components/Modal';
import Button from 'flarum/components/Button';


export default class GetpAppModal extends Modal {
    init() {
        super.init();
    }

    className() {
        return 'GetAppModal Modal--small';
    }

    title() {
        return "Aplikacja na Androida!";
    }

    content() {
        return (
            <div className="Modal-body">
                <div className="Form Form--centered">
                    <div className="Form-group">
                        <p>Pobierz aplikację Forum GPPH na Androida, aby mieć wygodny i szybki dostęp do treści w każdym miejscu!</p>
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=pl.rwdesign.forumgpph">
                            <span class="Button" style="background-color: green; color: white">Pobierz aplikację</span>
                            <p style="margin-top: 10px">Przycisk przeniesie Cię do Google Play Store</p>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
