import { extend } from 'flarum/extend';
import app from 'flarum/app';
import RankingsPage from './RankingsPage';
import addLink from './addLink';


app.initializers.add('tomaszrup-monthly', app => {
  app.routes.monthly = { path: '/monthly', component: RankingsPage.component() };
  addLink();
});
