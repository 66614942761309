import { extend } from 'flarum/extend';
import Application from 'flarum/Application';

import Footer from './components/Footer';
import modifyRankingsIcon from './components/modifyRankingsIcon';
import addModalToMobileApp from './components/addModalToMobileApp';

import addSwipeToMenu from './components/addSwipeToMenu';
import hideHeaderIfMobile from './components/hideHeaderIfMobile';

app.initializers.add('rwd-mobile-footer', () => {
    extend(Application.prototype, 'mount', () => {
        const footer = document.createElement('footer');

        m.mount(document.body.appendChild(footer), Footer.component());
        
        modifyRankingsIcon();
        addModalToMobileApp();
        //addSwipeToMenu();
        //hideHeaderIfMobile();
    });
});